<template>
  <div class="box">
    <a-spin :spinning="loading">
      <div class="card-title">
        财务信息
      </div>

      <a-row
        type="flex"
        class="balance-box"
      >
        <a-col flex="70px">
          <a-icon
            type="transaction"
            :style="{fontSize: '55px', color: 'rgb(95, 166, 248)' }"
          />
        </a-col>

        <a-col flex="200px">
          <a-statistic
            class="iotplt-statistic"
            title="账户余额"
            prefix="￥"
            :precision="2"
            :value="data.balance"
          />
        </a-col>

        <a-col
          :span="6"
          v-if="(isTopAgent && isSimiotContractingParty) || isMerchant"
        >
          <router-link :to="{ name: 'balance_recharge'}">
            <a-button type="primary">
              充值
            </a-button>
          </router-link>
        </a-col>

        <a-col flex="70px" v-if="this.agentType == 1">
          <a-icon
            type="transaction"
            :style="{fontSize: '55px', color: 'rgb(95, 166, 248)' }"
          />
        </a-col>

        <a-col flex="200px" v-if="this.agentType == 1">
          <a-statistic
            class="iotplt-statistic"
            title="佣金余额"
            prefix="￥"
            :precision="2"
            :value="data.commission_available"
          />
        </a-col>

        <a-col
          :span="6"
          v-if="this.agentType == 1"
        >
          <router-link :to="{ name: 'commission_withdrawal'}">
            <a-button type="primary">
              提现
            </a-button>
          </router-link>
        </a-col>
      </a-row>

      <a-row
        v-if="isTopAgent"
        :gutter="16"
        class="others-box"
      >
        <a-col :span="8">
          <a-space>
            <img
              src=""
              alt=""
              width="60"
            >
            <a-statistic
              title="信用额度(元)"
              :value="data.credit"
              :precision="2"
              class="iotplt-statistic"
            />
          </a-space>
        </a-col>

        <a-col :span="8">
          <a-statistic
            title="锁定金额(元)"
            :value="data.locked"
            :precision="2"
            class="iotplt-statistic"
          />
        </a-col>

        <a-col :span="8">
          <a-statistic
            title="可用额度(元)"
            :value="data.available"
            :precision="2"
            class="iotplt-statistic"
          />
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import { findHomeFinanceStatistics } from '@/api/home'
import { findAgentIsSimiotContractingParty } from '@/api/agent'

export default {
  name: 'FinanceStatistics',
  data() {
    return {
      loading: true,
      data: {},
      loadingSimiotContractingParty: true,
      isSimiotContractingParty: false // 主体是否有限公司
    }
  },
  mounted() {
    this.fetchData()
    if (this.isTopAgent) {
      this.fetchSimiotContractingPartyData()
    }
  },
  computed: {
    isTopAgent() {
      return this.$store.getters.userRole === 'top_agent'
    },
    isMerchant() {
      return this.$store.getters.userRole === 'merchant'
    },
    agentType() {
      return parseInt(this.$store.getters.userAgentType)
    }
  },
  methods: {
    fetchData() {
      this.loading = true
      findHomeFinanceStatistics(this.id, this.query).then((res) => {
        if (res.code === 0) {
          this.data = res.data
        }
        this.loading = false
      })
    },

    // 查询主体是否有限公司
    fetchSimiotContractingPartyData() {
      this.loadingSimiotContractingParty = true
      findAgentIsSimiotContractingParty(this.$store.getters.userAgentId).then((res) => {
        if (res.code === 0) {
          this.isSimiotContractingParty = res.data.is_simiot_contracting_party
        }
        this.loadingSimiotContractingParty = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.box {
  height: 235px;
  .card-title {
    color: @primary-color;
    font-size: 16px;
  }

  .balance-box {
    height: 135px;
    flex-flow: row wrap;
    align-content: space-around;
    justify-content: flex-start;
  }

  .others-box {
    height: 80px;
  }
}
</style>
