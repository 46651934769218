import request from '@/utils/request'

// 查询客户财务信息总览
export function findAgentFinanceOverview(agentId) {
  return request({
    url: `/agents/${agentId}/finance/overview`,
    method: 'get'
  })
}

// 查询客户责任销售
export function FindAgentSale(id) {
  return request({
    url: `/agents/${id}/sale`,
    method: 'get'
  })
}

// 查询客户API信息
export function findAgentApiInfo(agentId) {
  return request({
    url: `/agents/${agentId}/api_info`,
    method: 'get'
  })
}

// 查询客户名称
export function findAgentName(agentId) {
  return request({
    url: `/agents/${agentId}/name`,
    method: 'get'
  })
}

// 分销商登录查看企业客户列表
export function findAgencies(params) {
  return request({
    url: `/customer/agencies`,
    method: 'get',
    params: params
  })
}

// 分销商下的有效企业客户选项
export function findEffectiveAgencyOptions(params) {
  return request({
    url: `/customer/agencies/effective/options`,
    method: 'get',
    params: params
  })
}

// 分销商创建企业客户
export function createAgency(data) {
  return request({
    url: `/customer/agencies`,
    method: 'post',
    data
  })
}

// 分销商编辑企业客户表单数据
export function findAgencyForm(agentId) {
  return request({
    url: `/customer/agencies/${agentId}/form`,
    method: 'get'
  })
}

// 分销商编辑企业客户
export function updateAgency(agentId, data) {
  return request({
    url: `/customer/agencies/${agentId}`,
    method: 'put',
    data
  })
}

// 分销商批量删除企业客户
export function deleteAgencies(data) {
  return request({
    url: `/customer/agencies/batch`,
    method: 'delete',
    data
  })
}

// 分销商批量更新客户有效性
export function updateAgenciesEffective(data) {
  return request({
    url: `/customer/agencies/effective/batch`,
    method: 'patch',
    data
  })
}

// 分销商查看企业客户详情
export function findAgency(agentId) {
  return request({
    url: `/customer/agencies/${agentId}`,
    method: 'get'
  })
}

// 查询客户名称
export function findHomeAgentName(agentId) {
  return request({
    url: `/customer/agents/${agentId}/home/name`,
    method: 'get'
  })
}

// 导出企业客户列表
export function exportAgencies(agentId, data) {
  return request({
    url: `/agents/${agentId}/agencies/export`,
    method: 'post',
    data
  })
}

// 查询客户主体是否为SimiotCompany
export function findAgentIsSimiotContractingParty(agentId) {
  return request({
    url: `/agents/${agentId}/is_simiot_contracting_party`,
    method: 'get'
  })
}

// 查询客户短信信息总览
export function findAgentSmsesOverview(agentId) {
  return request({
    url: `/agents/${agentId}/smses/overview`,
    method: 'get'
  })
}

// 提交提现申请
export function withdrawalAgentCommission(data) {
  return request({
    url: `/customer/agents/commission/withdrawal`,
    method: 'post',
    data
  })
}

// 转存佣金到余额
export function transferAgentCommission(data) {
  return request({
    url: `/customer/agents/commission/transfer`,
    method: 'post',
    data
  })
}
